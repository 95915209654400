<template>
  <div>
    <v-dialog max-width="400" v-model="dialog">
      <v-card flat>
        <v-toolbar
          class="px-0 mx-0 font-weight-bold"
          flat
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)"
        >
          <v-toolbar-title>Video Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="$parent.video_settings_dialog = false">
            mdi-close
          </v-icon>
        </v-toolbar>
        <div class="pa-6">
          <div class="d-inline-flex align-center" style="width: 100%">
            <span class="font-weight-bold">Allow video download</span>
            <v-spacer></v-spacer>
            <v-switch
              inset
              color="primary"
              true-value="yes"
              false-value="no"
              v-model="form.allow_download"
            ></v-switch>
          </div>
          <div
            class="d-inline-flex align-center"
            style="width: 100%; display: none !important"
          >
            <span class="font-weight-bold">Allow subtitles</span>
            <v-spacer></v-spacer>
            <v-switch
              inset
              color="primary"
              true-value="yes"
              false-value="no"
              v-model="form.allow_subtitles"
            ></v-switch>
          </div>
        </div>
        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mb-5 capitalize rounded-xl"
            :loading="loading"
            @click="updateVideoSettings"
          >
            Save changes
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VideoSettings",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        allow_download: "",
        allow_subtitles: "",
      },
      loading: false,
    };
  },
  created() {
    this.getVideoSettings();
  },
  computed: {
    ...mapGetters({
      record: "recordings/recordDetails",
    }),
  },
  methods: {
    ...mapActions({
      video_settings: "recordings/video_settings",
      update_video_settings: "recordings/update_video_settings",
    }),
    async getVideoSettings() {
      try {
        const response = await this.video_settings(this.record.id);
        this.form.allow_download = response?.data?.allow_download;
        this.form.allow_subtitles = response?.data?.allow_subtitles;
      } catch (e) {
        console.log(e);
      }
    },
    async updateVideoSettings() {
      this.loading = true;
      this.form.recording_id = this.record.id;
      this.form.user_id = this.record.user_id;
      this.form["preview"] = "no";
      try {
        const response = await this.update_video_settings(this.form);
        this.loading = false;
        await this.getVideoSettings();
        this.$parent.video_settings_dialog = false;
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loading = false;
        this.$response.sendError(e);
      }
    },
  },
};
</script>
