<template>
  <div>
    <v-dialog max-width="400" v-model="dialog">
      <v-card flat>
        <v-toolbar
          class="px-0 mx-0 font-weight-bold"
          flat
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)"
        >
          <v-toolbar-title>Embed Code</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon ref="closeBtn" @click="$parent.embed_code_dialog = false">
            mdi-close
          </v-icon>
        </v-toolbar>
        <div class="pa-6" ref="codeContainer" style="width: 100%">
          <div class="text-center mb-5">
            Paste this HTML code in your website
          </div>
          <label>
            <textarea
              name="textarea"
              style="width: 100%"
              rows="6"
              ref="code"
              v-model="computeIframe"
              readonly
            ></textarea>
          </label>
        </div>
        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mb-5 capitalize rounded-xl"
            @click="copyCode"
          >
            Copy code
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :color="snackBarColor"
      :snackbar="snackbar"
      :snackBarText="snackBarText"
    />
  </div>
</template>

<script>
import Snackbar from "../../utils/Snackbar.vue";

export default {
  name: "EmbedCode",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    video_title: {
      type: String,
      default: "",
    },
    video_url: {
      type: String,
      default: "",
    },
  },
  components: {
    Snackbar,
  },
  data() {
    return {
      snackbar: false,
      snackBarColor: "",
      snackBarText: "",
    };
  },
  computed: {
    computeIframe() {
      return (
        '<iframe width="853" height="480" src="' +
        this.video_url +
        '" title="' +
        this.video_title +
        '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
      );
    },
  },
  methods: {
    copyCode() {
      const element = this.$refs.code;
      element.select();
      document.execCommand("copy");
      this.snackBarText = "Source code copied";
      this.snackbar = !this.snackbar;
      this.snackBarColor = "primary";
      setTimeout(() => {
        this.snackbar = false;
        window.getSelection().removeAllRanges();
      }, 2000);
    },
  },
};
</script>
