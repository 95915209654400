<template>
  <div>
    <v-dialog max-width="450" v-model="dialog" persistent>
      <v-card class="pb-2" flat>
        <v-toolbar
          class="px-0 mx-0 font-weight-bold"
          flat
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)"
        >
          <v-toolbar-title>Interaction Tool</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            ref="closeBtn"
            @click="$parent['interaction_tool_dialog'] = false"
          >
            mdi-close
          </v-icon>
        </v-toolbar>
        <div
          v-if="currentItem === 'interaction_type'"
          class="pa-6 text-left"
          style="width: 100%; position: relative"
        >
          <h3 class="mt-3">Interaction Type</h3>
          <v-autocomplete
            outlined
            dense
            :items="interaction_options"
            placeholder="Select Interaction"
            v-model="form['interaction_type']"
          ></v-autocomplete>
          <span
            class="error--text"
            style="position: absolute; bottom: 25px"
            v-if="form['interaction_type'] == null"
          >
            this field is required
          </span>
        </div>

        <div
          v-if="currentItem === 'appearance'"
          class="pa-6 text-left"
          style="width: 100%; position: relative"
        >
          <h3 class="mt-3">Appearance</h3>
          <v-autocomplete
            outlined
            dense
            :items="appearance_options"
            placeholder="Select when to appear"
            v-model="form['appearance']"
          ></v-autocomplete>
          <span
            class="error--text"
            style="position: absolute; bottom: 25px"
            v-if="form['appearance'] == null"
          >
            this field is required
          </span>
        </div>

        <div
          v-if="currentItem === 'before_video'"
          class="pa-6 text-left"
          style="width: 100%"
        >
          <h3 class="mt-n2">Appearance Selected - {{ appearanceText }}</h3>
          <small>{{ instructionText }}</small>
          <div
            v-if="form['appearance'] === 'during_video'"
            style="position: relative"
          >
            <v-text-field
              prefix="Time"
              v-model="timer"
              outlined
              dense
              style="max-width: 130px"
              placeholder="hh:mm:ss"
              @input="validate"
              type="tel"
              ref="timer"
            ></v-text-field>
            <span
              class="error--text"
              style="position: absolute; bottom: 5px"
              v-if="
                form['appearance'] === 'during_video' && form['timer'] == null
              "
            >
              this field is required
            </span>
          </div>

          <div
            :class="form['appearance'] !== 'during_video' ? 'mt-3' : ''"
          ></div>
          <div v-if="form['interaction_type'] === 'email_capture'">
            <v-checkbox
              v-model="form['form_fields']['name']"
              label="First & last name"
              class="my-0 py-0"
              true-value="yes"
              false-value="no"
            ></v-checkbox>
            <v-checkbox
              v-model="form['form_fields']['email']"
              label="Email"
              class="my-0 py-0"
              true-value="yes"
              false-value="no"
            ></v-checkbox>
            <v-checkbox
              v-model="form['allow_skip']"
              label="Allow skip"
              class="my-0 py-0"
              true-value="yes"
              false-value="no"
            ></v-checkbox>

            <h3 class="mt-n2">Upper Text</h3>
            <div>
              <v-text-field
                v-model="form['upper_text']"
                outlined
                dense
                placeholder="e.g. Enter your email to watch this video"
                class="input-height"
              ></v-text-field>
            </div>
            <h3>Lower Text</h3>
            <div style="position: relative">
              <v-text-field
                v-model="form['lower_text']"
                outlined
                dense
                placeholder="e.g. Learn more, Contact us, Download now"
                class="input-height"
                onkeydown="if (event.keyCode !== 8 && this.value.length === 70) return false"
              ></v-text-field>
              <small
                class="text-right"
                style="position: absolute; bottom: -14px; width: 100%"
              >
                {{ form["lower_text"] ? form["lower_text"].length : "0" }}/70
                <span class="error--text">(max 70 characters)</span>
              </small>
            </div>
          </div>
          <h3 class="mt-3">Button Text</h3>
          <div>
            <v-text-field
              v-model="form['button_text']"
              outlined
              dense
              placeholder="e.g. Learn more, Contact us, Download now"
              class="input-height"
            ></v-text-field>
          </div>

          <div v-if="form['interaction_type'] === 'call_to_action'">
            <h3 class="mt-n2">Redirect to</h3>
            <div style="position: relative">
              <v-text-field
                v-model="form['redirect_to']"
                outlined
                dense
                placeholder="Enter URL link"
                @keyup="validate"
              ></v-text-field>
              <span
                class="error--text"
                v-if="invalidRedirectUrl"
                style="position: absolute; bottom: 5px"
              >
                only https URLs is accepted
              </span>
            </div>

            <v-checkbox
              v-model="form['open_link_in_new_tab']"
              label="Open this link in a new tab"
              class="my-0 py-0"
              true-value="yes"
              false-value="no"
            ></v-checkbox>

            <v-checkbox
              v-model="form['allow_skip']"
              label="Allow skip"
              class="my-0 py-0"
              true-value="yes"
              false-value="no"
            ></v-checkbox>
          </div>
        </div>

        <v-card-actions class="my-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            v-if="['appearance', 'before_video'].includes(currentItem)"
            text
            class="secondary darken-2 mb-3 capitalize rounded-xl mr-3"
            @click="
              next(
                currentItem === 'appearance' ? 'interaction_type' : 'appearance'
              )
            "
          >
            Back
          </v-btn>
          <v-btn
            small
            v-if="currentItem !== 'before_video'"
            depressed
            class="primary white--text black--text mb-3 capitalize rounded-xl"
            @click="
              next(
                currentItem === 'interaction_type'
                  ? 'appearance'
                  : 'before_video'
              )
            "
          >
            Next
          </v-btn>
          <div v-if="currentItem === 'before_video'" class="d-inline-flex">
            <v-btn
              small
              outlined
              class="primary--text mb-3 capitalize mr-3 rounded-xl"
              @click="saveCTA('preview')"
            >
              Preview
            </v-btn>
            <v-btn
              small
              depressed
              class="primary mb-3 capitalize mr-3 rounded-xl"
              @click="saveCTA('save')"
              :loading="loading"
            >
              Save
            </v-btn>
            <v-btn
              small
              depressed
              class="secondary black--text mb-3 capitalize rounded-xl d-none d-md-block"
              @click="$parent['interaction_tool_dialog'] = false"
            >
              Cancel
            </v-btn>
          </div>
          <v-spacer v-if="currentItem === 'before_video'"></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EmbedCode",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      interaction_options: [
        { text: "Call-to-action", value: "call_to_action" },
        { text: "Email capture", value: "email_capture" },
      ],
      appearance_options: [
        { text: "Before video", value: "before_video" },
        { text: "During Video", value: "during_video" },
        { text: "After Video", value: "after_video" },
      ],
      currentItem: "interaction_type",
      form: {
        allow_skip: "yes",
        form_fields: { email: "yes", name: "yes" },
      },
      timer: "00:00:00",
      loading: false,
      invalidRedirectUrl: false,
    };
  },
  computed: {
    ...mapGetters({
      record: "recordings/recordDetails",
    }),
    appearanceText() {
      let text = this.form["appearance"];
      text =
        text.charAt(0).toUpperCase() +
        text.replace("_", " ").substring(1, text.length);
      return text;
    },
    instructionText() {
      let call_to_action_text =
        "Add call to action, redirect viewers to your website/page or store";
      let appearance = this.form["appearance"].split("_")[0];
      let textJoin = [];
      switch (appearance) {
        case "before":
          textJoin[0] = "before";
          textJoin[1] = "can";
          textJoin[2] = "";
          break;
        case "during":
          textJoin[0] = "when";
          textJoin[1] = "are";
          textJoin[2] = "ing";
          break;
        case "after":
          textJoin[0] = "after";
          textJoin[1] = "have";
          textJoin[2] = "ed";
      }

      let text =
        "Capture email " +
        textJoin[0] +
        " viewers " +
        textJoin[1] +
        " watch" +
        textJoin[2] +
        " the video";
      if (this.form["interaction_type"] === "call_to_action") {
        text = call_to_action_text;
      }
      return text;
    },
  },
  created() {
    this.getVideoSettings();
  },
  methods: {
    ...mapActions({
      update_video_settings: "recordings/update_video_settings",
      get_video_settings: "recordings/video_settings",
    }),
    next(value) {
      if (
        this.currentItem === "interaction_type" &&
        this.form["interaction_type"] == null
      )
        return;
      if (this.currentItem === "appearance" && this.form["appearance"] == null)
        return;
      this.currentItem = value;
      this.invalidRedirectUrl = false;
      this.form["timer"] = this.timer;
    },
    async getVideoSettings() {
      try {
        await this.get_video_settings(this.record.id);
      } catch (e) {
        console.log(e);
      }
    },
    async saveCTA(type) {
      if (type === "preview") {
        this.form["preview"] = "yes";
      } else {
        this.form["preview"] = "no";
      }
      if (!this.validate()) return;
      this.form["interaction_type"] === "call_to_action"
        ? (this.form["cta_button_text"] = this.form["button_text"])
        : (this.form["email_capture_button_text"] = this.form["button_text"]);
      this.form.recording_id = this.record.id;
      this.form.user_id = this.record.user_id;
      this.loading = true;
      try {
        const response = await this.update_video_settings(this.form);
        this.loading = false;
        await this.getVideoSettings();
        if (type !== "preview") {
          this.$response.sendSuccess(response);
          this.$parent.interaction_tool_dialog = false;
        } else {
          window.open(this.$parent["shareableLink"] + "?preview=yes");
        }
      } catch (e) {
        this.loading = false;
        this.$response.sendError(e);
      }
    },
    validate() {
      if (this.form["appearance"] !== "during_video") {
        delete this.form.timer;
      }

      if (
        this.form["interaction_type"] === "call_to_action" &&
        (this.form["redirect_to"] == null ||
          this.form["redirect_to"]["indexOf"]("https") === -1)
      ) {
        this.invalidRedirectUrl = true;
        return false;
      }

      this.invalidRedirectUrl = false;
      return !(
        this.form["appearance"] === "during_video" && this.form["timer"] == null
      );
    },
  },
};
</script>

<style scoped>
.input-height {
  height: 44px;
}
</style>
