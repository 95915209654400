<template>
  <div>
    <v-dialog max-width="450" v-model="dialog">
      <v-card flat>
        <v-toolbar
          class="px-0 mx-0 font-weight-bold"
          flat
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)"
        >
          <v-toolbar-title>Subtitles</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon ref="closeBtn" @click="$parent.subtitle_dialog = false">
            mdi-close
          </v-icon>
        </v-toolbar>
        <div class="pa-6 text-left" style="width: 100%">
          <h3>Subtitles</h3>
          <small class="d-block">
            Reach audience that do not speak your language. Dramatically
            increase the reach and engagement on your vide using subtitles
          </small>

          <h2>Original language</h2>
          <small class="d-block">
            This is the language spoken in your video
          </small>
        </div>

        <v-btn
          class="primary rounded-xl capitalize"
          text
          @click="[($parent.subtitle_dialog = false), (generateDialog = true)]"
        >
          Generate Subtitles
        </v-btn>
        <small class="active-text d-block" style="font-size: 11px">
          <v-icon size="12px" color="#6c9af5">mdi-cloud-upload-outline</v-icon>
          or upload subtitle file
        </small>

        <v-card-actions class="my-5">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mb-5 capitalize rounded-xl"
            @click="uploadSubtitle"
          >
            Save
          </v-btn>
          <v-btn
            depressed
            class="seconday black--text mb-5 capitalize rounded-xl"
            @click="$parent.subtitle_dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="450" v-model="generateDialog">
      <v-card flat>
        <v-toolbar
          class="px-0 mx-0 font-weight-bold"
          flat
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.3)"
        >
          <v-toolbar-title>Generate Subtitles</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon ref="closeBtn" @click="generateDialog = false">
            mdi-close
          </v-icon>
        </v-toolbar>
        <div class="pa-6 text-left" style="width: 100%">
          <v-chip
            color="red lighten-5"
            class="error--text"
            style="font-size: 11px"
          >
            <v-icon>mdi-alert-circle</v-icon>
            Select the correct language in your video for accurate captioning
          </v-chip>

          <h3 class="mt-3">Language</h3>
          <v-autocomplete outlined dense></v-autocomplete>
        </div>

        <v-card-actions class="my-5">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mb-5 capitalize rounded-xl"
            @click="generateSubtitle"
          >
            generateSubtitle
          </v-btn>
          <v-btn
            depressed
            class="seconday black--text mb-5 capitalize rounded-xl"
            @click="generateDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EmbedCode",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      generateDialog: false,
    };
  },
  methods: {
    async uploadSubtitle() {},
    generateSubtitle() {},
  },
};
</script>
